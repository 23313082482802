<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSave('MissedCallTemplates')"
    @cancel="onCancel('MissedCallTemplates')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'MissedCallTemplateCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      controllerName: 'MissedCallTemplates',
      pageHeader: 'Создание шаблона ответов для пропущенных звонков',
      breadcrumbs: [
        {
          text: 'Колл-центр',
          route: { name: 'CallCenterMain' },
        },
        {
          text: 'Шаблоны ответов для пропущенных звонков',
          route: { name: 'MissedCallTemplates' },
        },
        {
          text: 'Создание шаблона ответов для пропущенных звонков',
        },
      ],
      initialForm: {
        text: null,
        collectionKey: null,
        groupId: null,
      },
      form: null,
    };
  },

  computed: {
    ...mapState({
      missedCallTemplatesGroups: (state) =>
        state.common.missedCallTemplatesGroups,
    }),

    fields() {
      return [
        {
          form: [
            {
              type: 'textarea',
              key: 'text',
              label: 'Текст',
            },
            // {
            //   type: 'text',
            //   key: 'collectionKey',
            //   label: 'Коллекция ключей',
            // },
            {
              type: 'select',
              key: 'groupId',
              label: 'Группа шаблонов',
              list: this.missedCallTemplatesGroups.data,
            },
          ],
        },
      ];
    },
  },

  created() {
    this.getCommonList({ name: 'MissedCallTemplatesGroups' });
  },

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem',
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
